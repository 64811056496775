<template>
    <div class="notifications">
        <div class="notifications__header">
            <h2 class="notifications__header-text">{{ $t('summary.notifications.label') }}</h2>
        </div>
        <ul v-if="(incidents.length || maintenances.length)">
            <EventCard
                v-for="event in incidents"
                :key="`i-${event.id}`"
                :event="event"
                :type="0"
                :count="notificationsCount"
            />
            <EventCard
                v-for="event in maintenances"
                :key="`m-${event.id}`"
                :event="event"
                :type="1"
                :count="notificationsCount"
            />
        </ul>
        <NoEventsBox :text="$t('summary.notifications.no-events')" v-else />
    </div>
</template>
<script>
import Incident from './notification/Incident.vue'
import Maintenance from './notification/Maintenance.vue'
import EventCard from '@/components/EventCard.vue'
import NoEventsBox from './NoEventsBox.vue'
// import EventsList from '@/views/hub/components/events/EventsList.vue'
import { mapGetters } from 'vuex'

export default {
    components: {
        Incident,
        Maintenance,
        EventCard,
        NoEventsBox
    },
    computed: {
        ...mapGetters({
            config: 'config',
            incidents: 'incidents/current',
            maintenances: 'maintenances/current'
        }),
        notificationsCount () {
            return this.incidents.length + this.maintenances.length
        }
    }
}
</script>
<style lang="scss" scoped>
.notifications {
    @include breakpoint($lg) {
        margin-top: 32px;
        margin-bottom: 32px;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__header {
        padding: 16px;
    }

    &__header-text {
        font-weight: 600;
        font-size: 1.125rem;
        color: #29394A;
        margin-block-start: 0;
        margin-block-end: 0;
    }
}

</style>
