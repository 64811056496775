<script setup>
import IncidentsPopover from '@/views/hub/components/history/list/group/popovers/IncidentsPopover.vue'
import MaintenancesPopover from '@/views/hub/components/history/list/group/popovers/MaintenancesPopover.vue'
import { ref, computed, onMounted } from 'vue'
import api from '@/api-client'

const props = defineProps({
    event: {
        type: Object,
        required: true
    }
})
const emit = defineEmits(['close'])

const eventData = ref(null)
const fetchEventData = async () => {
    try {
        const { data } = await api
            .get(`${props.event.event_type === 0 ? 'incidents' : 'maintenances'}/${props.event.id}`)
        eventData.value = data
    } catch (e) {
        console.error(e)
    }
}
onMounted(fetchEventData)

const componentName = computed(() => props.event.event_type === 0 ? IncidentsPopover : MaintenancesPopover)
</script>

<template>
    <component
        v-if="!!eventData"
        :is="componentName"
        :incidents="[eventData]"
        :maintenances="[eventData]"
        :showAffectedServices="true"
        :showCloseButton="false"
        @close="emit('close')"
    />
</template>

<style scoped lang="scss">
.sx__event-modal.is-open {
    border-radius: 6px;

    .content {
        width: auto;
        box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
        border-radius: 6px;
    }
}
</style>
